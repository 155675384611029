import React, { useRef } from 'react'
import { IconAnalyticGeneral } from '../../../../../shared/icon/icon'
import { BreadcrumbView } from '../../../../../context/breadcrumb.context'
import { Column, ColumnDateTime, ColumnFilter, ColumnNum, TableBuilder } from '../../../../../shared/table-builder/table-builder'
import { CardFullHeightScrollY } from '../../../../../shared/card/card-full-height-scroll-y'
import { useApiListChunkReportGeneralOrg } from '../../../../../api-new/state/analytics'
import { Menu, MenuExportCsv, MenuExportExcel } from '../../../../../shared/menu/menu'

export const AnalyticOrgGeneral = () => {
  const apiListChunkReportGeneralOrg = useApiListChunkReportGeneralOrg()

  const handleReady = () => apiListChunkReportGeneralOrg.get(1000)

  const tableRef = useRef()
  const handleExportCsv = () => tableRef.current.exportCsv()
  const handleExportExcel = () => tableRef.current.exportExcel()

  const HeaderMenu = () =>
    <Menu>
      <MenuExportCsv onClick={handleExportCsv} show />
      <MenuExportExcel onClick={handleExportExcel} show />
    </Menu>

  return (
    <React.Fragment>
      <BreadcrumbView Icon={IconAnalyticGeneral} text="ANALYTICS GENERAL">
        <CardFullHeightScrollY title="General Analytics" HeaderMenu={HeaderMenu}>
          <TableBuilder ref={tableRef} apiList={apiListChunkReportGeneralOrg} sortField="sessionID" sortDir="desc" onReady={handleReady}>
            <Column field="sessionID" sortable />

            <ColumnFilter field="nodeName" header="Course" sortable />

            <Column field="email" sortable />
            <Column field="firstName" sortable />
            <Column field="lastName" sortable />

            <ColumnDateTime field="startOn" sortable />
            <ColumnDateTime field="endOn" sortable />

            <Column field="stepName" header="Step" sortable />
            <ColumnNum field="objectives" sortable />
            <ColumnNum field="objectivesCompleted" sortable />
            <ColumnNum field="attachments" sortable />
            <ColumnNum field="attachmentsCompleted" header="Attachments Viewed" sortable />

            <ColumnFilter field="os" sortable />
            <ColumnFilter field="platform" sortable />
            <Column field="appVersion" sortable />
            <ColumnFilter field="deviceModel" sortable />
            <ColumnFilter field="deviceType" sortable />
            <ColumnFilter field="vrDeviceModel" sortable />
            <ColumnFilter field="vrDeviceName" sortable />
            <Column field="vrDevicePresent" sortable />
            <Column field="vrDeviceTrackingOriginMode" sortable />
            <Column field="vrDeviceTrackingSpaceType" sortable />
            <Column field="vrDeviceRefreshRate" sortable />
            <ColumnFilter field="graphicsCard" sortable />
            <Column field="graphicsCardMemorySize" sortable />
            <ColumnFilter field="graphicsCardVendor" sortable />
            <ColumnFilter field="processor" sortable />
            <Column field="processorCount" sortable />
            <Column field="processorFrequency" sortable />
            <Column field="systemMemorySize" sortable />
            <Column field="isMultiPlayer" sortable />
            <Column field="locationCountry" sortable />
            <Column field="locationRegion" sortable />
            <Column field="locationCity" sortable />

            <Column field="groups" sortable />
          </TableBuilder>
        </CardFullHeightScrollY>
      </BreadcrumbView>
    </React.Fragment>
  )
}
