import React, { useMemo } from 'react'
import { ItemContent } from './item-content'
import { ItemChoice } from './item-choice'
import { IconCopy } from '../../../../../../../../shared/icon/icon'
import { ItemCitations } from './item-citation'
import { ItemCreated } from './item-created'

export const Item = ({ item }) => {
  const choice = useMemo(() => (item.result.choices.length ? item.result.choices[0] : 'NO CHOICE RETURNED'), [item])

  const handleCopy = () => navigator.clipboard.writeText(choice.message.content)

  return (
    <React.Fragment>
      <div className="d-flex flex-column">
        <div className="m-2">
          <ItemContent content={item.content} />
        </div>
        <div className="m-2">
          <ItemChoice choice={choice} />
        </div>
        <div className="m-2 d-flex">
          <div className="flex-fill d-flex">
            <div className="mx-1" style={{ cursor: 'pointer' }} onClick={handleCopy}>
              <IconCopy />
            </div>
            <div className="mx-1">
              <ItemCitations citations={item.result.citations} />
            </div>
          </div>
          <ItemCreated value={item.result.created} />
        </div>
        <div className="border-bottom border-2 m-3" />
      </div>
    </React.Fragment>
  )
}
