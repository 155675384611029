import { useReducer } from 'react'

const TYPE_ADD = 'ADD'
const TYPE_REMOVE = 'REMOVE'

export const useReducerListener = () => {
  const [listeners, dispatch] = useReducer((state, action) => {
    switch (action.type) {
      case TYPE_ADD:
        return [...state, action.payload]
      case TYPE_REMOVE:
        return state.filter(i => i !== action.payload)
      default:
        return state
    }
  }, [])

  const listenerAdd = item => dispatch({ type: TYPE_ADD, payload: item })
  const listenerRemove = item => dispatch({ type: TYPE_REMOVE, payload: item })

  const listenerCall = item => {
    listeners.forEach(listener => {
      listener(item)
    })
  }

  return { listeners, listenerAdd, listenerRemove, listenerCall }
}
