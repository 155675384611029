import React from 'react'
import { BreadcrumbView } from '../../../../../../context/breadcrumb.context'
import { IconAiDocument } from '../../../../../../shared/icon/icon'
import { CardFullHeightScrollY } from '../../../../../../shared/card/card-full-height-scroll-y'
import { Card } from '../../../../../../shared/card/card'
import { Route, useRouteMatch, Link } from '../../../../../../../node_modules/react-router-dom/cjs/react-router-dom.min'
import { AiDocumentList } from './ai-document-list'
import { AiSettings } from './ai-settings'
import { AiChat } from './ai-chat/ai-chat'

export const AiDashboard = ({ nodeID }) => {
  const { url } = useRouteMatch()

  return (
    <React.Fragment>
      <BreadcrumbView Icon={IconAiDocument} text="ai">
        <CardFullHeightScrollY title="AI Dashboard">
          <div className="d-flex justify-content-evenly flex-wrap">
            <div>
              <Card title="DOCUMENTS">
                <Link to={`${url}/documents`}>Documents</Link>
              </Card>
            </div>
            <div>
              <Card title="SETTINGS">
                <Link to={`${url}/settings`}>Settings</Link>
              </Card>
            </div>
            <div>
              <Card title="CHAT">
                <Link to={`${url}/chat`}>Chat</Link>
              </Card>
            </div>
            <div>
              <Card>ANALYTICS</Card>
            </div>
          </div>
        </CardFullHeightScrollY>
      </BreadcrumbView>

      <Route path={`${url}/documents`}>
        <AiDocumentList nodeID={nodeID} />
      </Route>

      <Route path={`${url}/settings`}>
        <AiSettings nodeID={nodeID} />
      </Route>

      <Route path={`${url}/chat`}>
        <AiChat nodeID={nodeID} />
      </Route>
    </React.Fragment>
  )
}
