import React from 'react'
import { Route } from 'react-router-dom'
import { LoginAccount } from './page/login-account'
import { LoginPin } from './page/login-pin'
import { LoginEmail } from './page/login-email'
import { LoginProviders } from './page/login-provider'
import { PasswordRequest } from './page/password-request'
import { PasswordResult } from './page/password-result'
import { PasswordReset } from './page/password-reset'

export function LayoutAuth() {
  return (
    <React.Fragment>
      <Route path="/auth/email" component={LoginEmail} />
      <Route path="/auth/providers" component={LoginProviders} />
      <Route path="/auth/pin" component={LoginPin} />
      <Route path="/auth/account" component={LoginAccount} />

      <Route path="/auth/password-recover/request" component={PasswordRequest} />
      <Route path="/auth/password-recover/result/:email" component={PasswordResult} />
      <Route path="/auth/password-recover/reset/:resetToken" component={PasswordReset} />
    </React.Fragment>
  )
}
