import React, { useMemo } from 'react'
import { BreadcrumbView } from '../../../../../context/breadcrumb.context'
import { Form, FormText, FormCheckbox, FormSubmit } from '../../../../../shared/form/form'
import { useParamsInt } from '../../../../../shared/hooks/location'
import { IconEdit, IconOrganization } from '../../../../../shared/icon/icon'
import { CardFullHeightScrollY } from '../../../../../shared/card/card-full-height-scroll-y'
import { useApiFormProvider } from '../../../../../api-new/state/auth'
import { Menu, MenuDelete, MenuItem } from '../../../../../shared/menu/menu'
import { useRoleSysProvider } from '../../../../../role/hook/role-sys'

export const ProviderEdit = () => {
  const { providerID } = useParamsInt()

  const apiFormProvider = useApiFormProvider()

  const roleSysProvider = useRoleSysProvider()

  const title = useMemo(() => `edit ${apiFormProvider.form.providerName}`, [apiFormProvider.form])

  const handlePath = async () => await apiFormProvider.get(providerID)
  const handleSubmit = async data => await apiFormProvider.put(data.id, data)

  const HeaderMenu = () =>
    <Menu>
      <MenuItem Icon={IconOrganization} text="organizations" to={`/admin/provider/list/edit/${providerID}/organization`} show={roleSysProvider.accessUpdate} />
      <MenuDelete to={`/admin/provider/list/edit/${providerID}/delete`} show={roleSysProvider.accessDelete} />
    </Menu>

  return (
    <React.Fragment>
      <BreadcrumbView Icon={IconEdit} text={title} onPath={handlePath}>
        <CardFullHeightScrollY title={title} HeaderMenu={HeaderMenu}>
          <Form state={apiFormProvider.form} onSubmit={handleSubmit}>
            <FormText label="identifier" name="identifier" readOnly />
            <FormText label="provider name" name="providerName" required autoFocus />
            <FormText label="display name" name="displayName" required />
            <FormText label="client id" name="clientID" required />
            <FormText label="client secret" name="clientSecret" required />
            <FormText label="scopes (comma seperated)" name="scopes" required />
            <FormText label="issuer (/.well-known/openid-configuration)" name="issure" required />
            <FormText label="signout url" name="signoutURL" emptyNull />
            <FormText label="token url (facebook)" name="tokenURL" emptyNull />
            <FormText label="replace uri (comma seperated)" name="replaceURI" emptyNull />
            <FormText label="append uri (comma seperated)" name="appendURI" emptyNull />
            <FormText label="icon svg" name="iconSVG" emptyNull />
            <FormText label="url" name="url" emptyNull />
            <FormCheckbox label="is active" name="active" />
            <FormSubmit text="save provider" />
          </Form>
        </CardFullHeightScrollY>
      </BreadcrumbView>
    </React.Fragment>
  )
}
