import React, { useContext } from 'react'
import { useReducerUpload } from '../hook/reducer-upload'
import { useReducerListener } from '../hook/reducer-listener'

const Context = React.createContext()

export const useContextUploadManager = () => useContext(Context)

export const ContextUploadManager = ({ children }) => {
  const { uploads, uploadInsert, uploadComplete, uploadRemove } = useReducerUpload()
  const { listenerAdd, listenerRemove, listenerCall } = useReducerListener()

  return (
    <Context.Provider value={{ uploads, uploadInsert, uploadComplete, uploadRemove, listenerAdd, listenerRemove, listenerCall }}>
      {children}
    </Context.Provider>
  )
}
