import React, { useState } from 'react';
import { DisplayAuth } from '../../context/display-auth';
import { HeaderLogo } from './header-logo';
import { DisplayFeatureTradeShow } from '../../context/display-feature-trade-show';
import { LoginSysAdmin } from '../../role/components/login-sys-admin';
import { RoleOrgAnalytic, RoleOrgDashboard, RoleOrgDOC, RoleOrgGroup, RoleOrgNode, RoleOrgTradeshow, RoleOrgUser } from '../../role/components/role-org';
import { DisplayOrgCompany } from '../../context/display-org-company';
import { Link } from 'react-router-dom';
import { OffcanvasClose } from '../../shared/component/offcanvas';
import {
	IconAnalytic,
	IconContent,
	IconDashboard,
	IconUsers,
	IconApp,
	IconCaretDown,
	IconOrganization,
	IconGroup,
	IconTradeShow,
	IconLogins,
	IconProvider,
	IconEmail,
	IconDocs,
	IconRole,
	IconAnalyticGeneral,
	IconAnalyticCustom,
	IconCaretUp,
	IconEvent,
	IconError,
	IconTemplate
} from '../../shared/icon/icon';
import { useRandomID } from '../../shared/hooks/random-id';
import { useDocAuthRedirect } from '../../shared/hooks/doc';
import {
	RoleSysApp,
	RoleSysAuthStatus,
	RoleSysDashboard,
	RoleSysLogin,
	RoleSysOrganization,
	RoleSysProvider,
	RoleSysSendgridEvent
} from '../../role/components/role-sys';
import { LoginSysRoot } from '../../role/components/login-sys-root';

const Brand = () => {
  return (
    <div className='navbar'>
      <div className='container-fluid'>
        <div className='navbar-brand w-100'>
          <div className='d-flex justify-content-around'>
            <HeaderLogo gray />
          </div>
        </div>
      </div>
    </div>
  );
};

const SidebarContainer = ({ children }) => {
  return (
    <ul className='nav nav-pills flex-column'>
      {children}
    </ul>
  );
};

const SidebarItem = ({ children }) => {
  return (
    <li className='nav-item p-1 fs-6'>
      {children}
    </li>
  );
};

const SidebarTitle = ({ children, title }) => {
  return (
    <React.Fragment>
      <SidebarItem>
        <span className='nav-link text-decoration-none text-uppercase edxr-sidebar-left-item edxr-pointer' style={{ fontSize: '70%', fontWeight: 'bold' }}>
          {title}
        </span>
      </SidebarItem>
      <React.Fragment>
        {children}
      </React.Fragment>
    </React.Fragment>
  );
};

const SidebarTitleToggle = ({ children, title }) => {
  const [expand, updateExpand] = useState(false);

  const handleClick = () => {
    updateExpand(!expand);
  };

  return (
    <React.Fragment>
      <SidebarItem>
        <span
          className='nav-link text-decoration-none text-uppercase edxr-sidebar-left-item edxr-pointer'
          style={{ fontSize: '70%', fontWeight: 'bold' }}
          onClick={handleClick}
				>
          <div className='d-flex'>
            <div className='flex-fill'>
              {title}
            </div>
            <div>
              {expand ? <IconCaretUp /> : <IconCaretDown />}
            </div>
          </div>
        </span>
      </SidebarItem>
      <React.Fragment>
        {expand && children}
      </React.Fragment>
    </React.Fragment>
  );
};

const SidebarItemText = ({ Icon, title, IconState, onClick }) => {
  const handleClick = () => onClick && onClick();

  return (
    <div className='d-flex nav-link text-uppercase fw-light edxr-sidebar-left-item edxr-pointer' onClick={handleClick}>
      <span className='text-center' style={{ width: 40 }}>
        {Icon && <Icon size='lg' />}
      </span>
      <span className='mx-2 flex-fill'>
        {title}
      </span>
      {IconState && <IconState size='lg' />}
    </div>
  );
};

const SidebarItemNavLink = ({ Icon, title, to, target }) => {
  return (
    <OffcanvasClose target={target}>
      <SidebarItem>
        <Link className='text-decoration-none' to={to || ''}>
          <SidebarItemText Icon={Icon} title={title} />
        </Link>
      </SidebarItem>
    </OffcanvasClose>
  );
};

const SidebarItemNavClick = ({ Icon, title, onClick, target }) => {
  return (
    <OffcanvasClose target={target}>
      <SidebarItem>
        <SidebarItemText Icon={Icon} title={title} onClick={onClick} />
      </SidebarItem>
    </OffcanvasClose>
  );
};

const SidebarItemCollapse = ({ children, Icon, title }) => {
  const randomID = useRandomID();

  const [open, updateOpen] = useState(false);

  const handleToggle = () => updateOpen(!open);

  return (
    <SidebarItem>
      <div className={``} onClick={handleToggle}>
        <SidebarItemText Icon={Icon} title={title} IconState={open ? IconCaretUp : IconCaretDown} />
      </div>
      <div className={`ms-3 collapse ${open && 'show'}`} id={`${randomID}`}>
        <SidebarContainer>
          {children}
        </SidebarContainer>
      </div>
    </SidebarItem>
  );
};

/// /////////////////////////////////////////////////
/// /////////////////////////////////////////////////
/// /////////////////////////////////////////////////

export const SidebarLeft = ({ target }) => {
  const docAuthRedirect = useDocAuthRedirect();

  const handleDocs = async () => docAuthRedirect();

  return (
    <React.Fragment>
      <div className='d-flex flex-column bg-primary h-100'>
        <div>
          <div className='border-bottom border-secondary' style={{ height: '58px' }}>
            <Brand />
          </div>
        </div>

        <div className='flex-fill overflow-scroll'>
          <SidebarContainer className='nav nav-pills flex-column'>
            <LoginSysRoot>
              <SidebarTitleToggle title='ROOT'>
                <SidebarItemCollapse title='ROLE SYS' Icon={IconRole}>
                  <SidebarItemNavLink title='ROLE' Icon={IconRole} to='/root/role-sys/list' target={target} />
                  <SidebarItemNavLink title='PERMISSIONS' Icon={IconRole} to='/root/role-sys-permission/list' target={target} />
                </SidebarItemCollapse>

                <SidebarItemCollapse title='ROLE ORG' Icon={IconRole}>
                  <SidebarItemNavLink title='ROLES' Icon={IconRole} to='/root/role-org/list' target={target} />
                  <SidebarItemNavLink title='PERMISSIONS' Icon={IconRole} to='/root/role-org-permission/list' target={target} />
                </SidebarItemCollapse>

                <SidebarItemNavLink title='SYSTEM ADMINS' Icon={IconUsers} to='/root/sys-admin/list' target={target} />
                <SidebarItemNavLink title='ANALYTICS SETUP' Icon={IconAnalytic} to='/root/analytic-setup' target={target} />
              </SidebarTitleToggle>
            </LoginSysRoot>

            <LoginSysAdmin>
              <SidebarTitleToggle title='SYSTEM ADMIN'>
                <RoleSysDashboard accessRead>
                  <SidebarItemNavLink title='DASHBOARD' Icon={IconDashboard} to='/admin/dashboard' target={target} />
                  <SidebarItemNavLink title='EVENT LOG' Icon={IconEvent} to='/admin/dashboard/log-event/list' target={target} />
                  <SidebarItemNavLink title='ERROR LOG' Icon={IconError} to='/admin/dashboard/log-error/list' target={target} />
                </RoleSysDashboard>

                <RoleSysApp accessRead>
                  <SidebarItemNavLink title='APPS' Icon={IconApp} to='/admin/app' target={target} />
                </RoleSysApp>

                <RoleSysOrganization accessRead>
                  <SidebarItemNavLink title='ORGANIZATIONS' Icon={IconOrganization} to='/admin/organization/list' target={target} />
                </RoleSysOrganization>
                <RoleSysLogin accessRead>
                  <SidebarItemNavLink title='LOGINS' Icon={IconLogins} to='/admin/login/list' target={target} />
                </RoleSysLogin>
                <RoleSysAuthStatus accessRead>
                  <SidebarItemNavLink title='AUTH STATUS' Icon={IconLogins} to='/admin/auth-status/list' target={target} />
                </RoleSysAuthStatus>
                <RoleSysProvider accessRead>
                  <SidebarItemNavLink title='PROVIDERS' Icon={IconProvider} to='/admin/provider/list' target={target} />
                </RoleSysProvider>
                <RoleSysSendgridEvent accessRead>
                  <SidebarItemNavLink title='SENDGRID EVENTS' Icon={IconEmail} to='/admin/sendgrid-event/list' target={target} />
                </RoleSysSendgridEvent>
              </SidebarTitleToggle>
            </LoginSysAdmin>

            <DisplayAuth>
              <SidebarTitle title='NAVIGATION'>
                <RoleOrgDashboard accessRead>
                  <SidebarItemNavLink title='DASHBOARD' Icon={IconDashboard} to='/org/dashboard' target={target} />
                </RoleOrgDashboard>

                <RoleOrgAnalytic accessRead>
                  <SidebarItemCollapse title='ANALYTICS' Icon={IconAnalytic}>
                    <SidebarItemNavLink title='GENERAL' Icon={IconAnalyticGeneral} to='/org/analytic/general' target={target} />
                    <SidebarItemNavLink title='CUSTOM' Icon={IconAnalyticCustom} to='/org/analytic/custom' target={target} />
                  </SidebarItemCollapse>
                </RoleOrgAnalytic>

                <RoleOrgNode accessRead>
                  <SidebarItemNavLink title='CONTENT' Icon={IconContent} to='/org/node-content' target={target} />
                  <SidebarItemNavLink title='TEMPLATES' Icon={IconTemplate} to='/org/node-template' target={target} />
                  <LoginSysAdmin>
                    <SidebarItemNavLink title='ASSET LIBARAY' Icon={IconContent} to='/org/node-library' target={target} />
                  </LoginSysAdmin>
                </RoleOrgNode>

                <RoleOrgUser accessRead>
                  <SidebarItemNavLink title='USERS' Icon={IconUsers} to='/org/user/list' target={target} />
                </RoleOrgUser>
                <RoleOrgGroup accessRead>
                  <SidebarItemNavLink title='GROUPS' Icon={IconGroup} to='/org/group/list' target={target} />
                </RoleOrgGroup>
                <RoleOrgTradeshow accessRead>
                  <DisplayFeatureTradeShow>
                    <SidebarItemNavLink title='TRADE SHOWS' Icon={IconTradeShow} to='/org/tradeshow/list' target={target} />
                  </DisplayFeatureTradeShow>
                </RoleOrgTradeshow>
                <DisplayOrgCompany>
                  <RoleOrgDOC accessRead>
                    <SidebarItemNavClick title='DOCS' Icon={IconDocs} onClick={handleDocs} target={target} />
                  </RoleOrgDOC>
                </DisplayOrgCompany>
              </SidebarTitle>
            </DisplayAuth>
          </SidebarContainer>
        </div>
      </div>
    </React.Fragment>
  );
};
