import React from 'react'
import {  useAuthContext } from '../context/auth.context'
import { useQueryParam } from '../shared/hooks/location'
import { useLoad } from '../shared/hooks/load'
import { useHistory } from 'react-router-dom'

export const OpenAuth = () => {
  const history = useHistory()

  const token = useQueryParam('token')
  let redirectUrl = useQueryParam('redirect-url')

  const authContext = useAuthContext()

  useLoad(async () => {
    try {
      if (redirectUrl) {
        await authContext.actions.loginProvider(token)
        window.location = redirectUrl
      }

      await authContext.actions.loginProvider(token)

      history.push('/')
    } catch {}
  }, [])

  return <React.Fragment />
}
