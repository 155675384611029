import { useEffect, useRef, useState } from 'react'

export const InputMessage = ({ submitting, onSubmit }) => {
  const refInput = useRef()
  const [value, updateValue] = useState('')

  useEffect(() => {
    const action = () => {
      let id = setTimeout(() => {
        refInput.current && refInput.current.focus()
      }, 500)

      return id
    }

    let id = action()

    return () => {
      clearTimeout(id)
    }
  }, [])

  const handleChange = e => updateValue(e.target.value)

  const handleSubmit = async () => {
    await onSubmit(value.trim())
    updateValue('')

    setTimeout(() => {
      refInput.current && refInput.current.focus()
    }, 100)
  }

  const handleKeyUp = e => {
    if (e.code === 'Enter' && value.length) {
      handleSubmit()
    }
  }

  return (
    <div className="input-group">
      <input ref={refInput} className="form-control" value={value} disabled={submitting} placeholder="question" onChange={handleChange} onKeyUp={handleKeyUp} />
      <span className="input-group-text" onClick={handleSubmit}>
        {submitting ? <div className="m-1 spinner-border spinner-border-sm text-primary" /> : <span>search</span>}
      </span>
    </div>
  )
}
