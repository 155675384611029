import React, { useMemo } from 'react'
import { ButtonPrimary } from '../shared/legacy/button'
import { useQueryParam } from '../shared/hooks/location'
import { CardFullHeightScrollY } from '../shared/card/card-full-height-scroll-y'
import { AlertError } from '../shared/alert/alert-error'
import { useHistory } from '../../node_modules/react-router-dom/cjs/react-router-dom.min'

export const OpenError = () => {
  const history = useHistory()
  const errMsg = useQueryParam('error')

  const error = useMemo(() => (errMsg ? atob(errMsg) : ''), [errMsg])

  const handleContinue = () => history.replace(`/auth/email`)

  return (
    <React.Fragment>
      <CardFullHeightScrollY title="Signin Error">
        <div className="m-2">
          <AlertError error={error} />
        </div>
        <div className="py-2">
          <ButtonPrimary text="continue" onClick={handleContinue} />
        </div>
      </CardFullHeightScrollY>
    </React.Fragment>
  )
}
