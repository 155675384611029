import React, { useMemo, useState } from 'react'
import { useQueryParam } from '../shared/hooks/location'
import { useLoad } from '../shared/hooks/load'
import { CardFullHeightScrollY } from '../shared/card/card-full-height-scroll-y'
import { useApiFormAuthProvider } from '../api-new/state/auth'
import { AlertError } from '../shared/alert/alert-error'

export const OpenOrganization = () => {
  const apiFormAuthProvider = useApiFormAuthProvider()

  const [organizations, updateOrganizations] = useState([])

  const token = useQueryParam('token')

  const showOrganizations = useMemo(() => !!organizations.length, [organizations])

  useLoad(async () => {

    try {
      let organizations = await apiFormAuthProvider.get(`${token}/organization`)

      if (organizations) {
        updateOrganizations(organizations)
        return
      } else {
        let url = new URL(`${process.env.REACT_APP_SITE}/open/complete`)
        url.searchParams.set('token', token)
        window.location = url.href
      }

    } catch{}
    
  }, [])

  const handleSelect = async organization => {
    
    try {
      await apiFormAuthProvider.postArg(`/${token}/organization`, organization)

      let url = new URL(`${process.env.REACT_APP_SITE}/open/complete`)
      url.searchParams.set('token', token)
      window.location = url.href
    } catch {}
  }

  return (
    <React.Fragment>
      <AlertError error={apiFormAuthProvider.error} />
      {showOrganizations &&
        <CardFullHeightScrollY title="select organization">
          <div className="table-responsive">
            <table className="table table-striped table-hover m-2">
              <tbody>
                {organizations.map((organization, i) => <OrganizationLine key={i} organization={organization} onSelect={handleSelect} />)}
              </tbody>
            </table>
          </div>
        </CardFullHeightScrollY>}
    </React.Fragment>
  )
}

const OrganizationLine = ({ organization, onSelect }) => {
  const handleSelect = () => onSelect && onSelect(organization)

  return (
   <React.Fragment>
      <tr onClick={handleSelect} style={{ cursor: 'pointer' }}>
        <td>
          <span className="m-2">
            {organization.name}
          </span>
        </td>
      </tr>
   </React.Fragment>
  )
}
