import React, { forwardRef, useEffect, useContext, useImperativeHandle, useMemo, useState } from 'react'
import { FormProvider, useForm, useFormState } from 'react-hook-form'
import { Prompt } from 'react-router-dom'
import moment from 'moment'

import { ButtonCancel, FormSubmit, FormSubmitCancel, FormSubmitBack, FormReset } from './form-submit'
import { FormatLabel } from './format-label'
import { FormHidden } from './form-hidden'
import { FormHiddenText } from './form-hidden-text'
import { FormHiddenNumber } from './form-hidden-number'
import { FormHiddenBool } from './form-hidden-bool'
import { FormText } from './form-text'
import { FormTextArea } from './form-text-area'
import { FormEmail } from './form-email'
import { FormPassword } from './form-password'
import { FormNumber } from './form-number'
import { FormDate } from './form-date'
import { FormCheckbox } from './form-checkbox'
import { FormCheckList } from './form-checkbox-list'
import { FormCheckboxConfirm } from './form-checkbox-confirm'
import { FormTextConfirm } from './form-text-confirm'
import { FormYesNo } from './form-yes-no'
import { FormURL } from './form-url'
import { FormSelectListObj } from './form-select-list-obj'
import { FormSelectListStr } from './form-select-list-str'
import { FormSelectListNum } from './form-select-list-num'
import { FormPasswordValidate } from './form-password-validate'
import { IconClickReload } from '../icon/icon-click'

export {
  ButtonCancel,
  FormSubmit,
  FormSubmitCancel,
  FormSubmitBack,
  FormReset,
  FormatLabel,
  FormHidden,
  FormHiddenText,
  FormHiddenNumber,
  FormHiddenBool,
  FormText,
  FormTextArea,
  FormEmail,
  FormPassword,
  FormNumber,
  FormDate,
  FormCheckbox,
  FormCheckList,
  FormCheckboxConfirm,
  FormTextConfirm,
  FormYesNo,
  FormURL,
  FormSelectListObj,
  FormSelectListStr,
  FormSelectListNum,
  FormPasswordValidate
}

const FormCustomContext = React.createContext()

const FormCustomProvider = ({ children }) => {
  const [formDisabled, updateFormDisabled] = useState(false)

  return <FormCustomContext.Provider value={{ formDisabled, updateFormDisabled }} children={children} />
}

export const useFormDisable = () => {
  const { isSubmitting } = useFormState()
  const { formDisabled } = useContext(FormCustomContext)

  return useMemo(() => isSubmitting || formDisabled, [isSubmitting, formDisabled])
}

///////////////////////////////

export const Form = forwardRef(({ children, state, disabled, onIsDirty, onHasError, onSubmit, onChange, onRefresh, enforceBlocking }, ref) => {
  const methods = useForm({ defaultValues: state || {} })

  useEffect(
    () => {
      if (methods.formState.isDirty) {
        let values = methods.getValues()
        onChange && onChange(values)
      }
    },
    // eslint-disable-next-line react-hooks/exhaustive-deps
    [methods.formState]
  )

  let values = methods.getValues()

  let isBlocking = useMemo(() => !!enforceBlocking && methods.formState.isDirty, [enforceBlocking, methods.formState.isDirty])

  useEffect(
    () => {
      onHasError && onHasError(!!Object.keys(methods.formState.errors).length)
    },
    // eslint-disable-next-line react-hooks/exhaustive-deps
    [methods.formState]
  )

  useEffect(
    () => {
      onIsDirty && onIsDirty(methods.formState.isDirty)
    },
    // eslint-disable-next-line react-hooks/exhaustive-deps
    [methods.formState]
  )

  useEffect(
    () => {
      methods.reset(state)
    },
    [methods, state]
  )

  useImperativeHandle(ref, () => ({
    reset: () => {
      methods.reset(state)
    }
  }))

  return (
    <FormCustomProvider>
      <FormProvider {...methods}>
        <form onSubmit={methods.handleSubmit(onSubmit)} disabled={disabled}>
          <Prompt when={isBlocking} message="This page has unsaved data, are you sure you want to leave page before saving?" />
          <FormBody values={values} disabled={disabled} onRefresh={onRefresh}>
            {children}
          </FormBody>
        </form>
      </FormProvider>
    </FormCustomProvider>
  )
})

const FormBody = ({ children, values, disabled, onRefresh }) => {
  const { updateFormDisabled } = useContext(FormCustomContext)
  const { isSubmitting } = useFormState()

  useEffect(
    () => {
      updateFormDisabled(disabled)
    },
    // eslint-disable-next-line react-hooks/exhaustive-deps
    [disabled]
  )

  let lastUpdated = useMemo(() => (values.updatedAt ? moment(values.updatedAt).fromNow() : null), [values.updatedAt])

  return (
    <React.Fragment>
      <div>
        {children}
      </div>
      <div className="text-end">
        {lastUpdated &&
          <small>
            last updated {lastUpdated}
          </small>}
        <small>
          {isSubmitting && <span className="mx-1 spinner-border spinner-border-sm" />}
          {!isSubmitting && onRefresh && <IconClickReload onClick={onRefresh} />}
        </small>
      </div>
    </React.Fragment>
  )
}
