import React, { useMemo, useState } from 'react'
import Cropper from 'react-easy-crop'
import { imageCropConvert } from './image-crop-convert'
import { ButtonPrimary, ButtonSecondary } from '../../../../../../../../shared/component/button'

export const ImageCropper = ({ imageURL, width, height, aspect, onContinue, onCancel }) => {
  const [crop, setCrop] = useState({ x: 0, y: 0 })
  const [zoom, setZoom] = useState(1)

  const [croppedPixels, setCroppedAreaPixels] = useState(1)

  const onCropComplete = (croppedArea, croppedAreaPixels) => setCroppedAreaPixels(croppedAreaPixels)

  const handleContinue = async () => {
    const newImageURL = await imageCropConvert(imageURL, croppedPixels)
    onContinue && onContinue(newImageURL)
  }

  const handleCancel = () => onCancel && onCancel()

  return (
    <React.Fragment>
      <div>
        <div>
          <div style={{ position: 'relative', width: width, height: height }}>
            <Cropper image={imageURL} crop={crop} zoom={zoom} aspect={aspect} onCropChange={setCrop} onCropComplete={onCropComplete} onZoomChange={setZoom} />
          </div>
        </div>
        <div>
          <ButtonSecondary onClick={handleCancel}>cancel</ButtonSecondary>
          <ButtonPrimary onClick={handleContinue}>continue</ButtonPrimary>
        </div>
      </div>
    </React.Fragment>
  )
}
