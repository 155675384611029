import { useReducer } from 'react'

const TYPE_INSERT = 'INSERT'
const TYPE_COMPLETE = 'COMPLETE'
const TYPE_REMOVE = 'REMOVE'

const initialState = []

export const useReducerUpload = () => {
  const [uploads, dispatch] = useReducer((state, action) => {
    switch (action.type) {
      case TYPE_INSERT:
        return [...state, ...action.payload]
      case TYPE_COMPLETE:
        let cleanIndex = state.findIndex(i => i.asset.key === action.payload.asset.key)
        state[cleanIndex].file = null
        return [...state]
      case TYPE_REMOVE:
        return state.filter(i => i.asset.key !== action.payload.asset.key)
      default:
        return state
    }
  }, initialState)

  const uploadInsert = items => dispatch({ type: TYPE_INSERT, payload: items })
  const uploadComplete = item => dispatch({ type: TYPE_COMPLETE, payload: item })
  const uploadRemove = item => dispatch({ type: TYPE_REMOVE, payload: item })

  return { uploads, uploadInsert, uploadComplete, uploadRemove }
}
