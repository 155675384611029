import React, { useMemo } from 'react'
import { BreadcrumbView } from '../../../../../../context/breadcrumb.context'
import { CardFullHeightScrollY } from '../../../../../../shared/card/card-full-height-scroll-y'
import { IconAiDocument } from '../../../../../../shared/icon/icon'
import { Form, FormCheckbox, FormSelectListStr, FormSubmitBack, FormText } from '../../../../../../shared/form/form'
import { useApiFormConfig, useApiListConfig } from '../../../../../../api-new/state/ai'
import { Menu, MenuDelete } from '../../../../../../shared/menu/menu'
import { FormRange } from '../../../../../../shared/form/form-range'

export const AiSettings = ({ nodeID }) => {
  const apiFormConfig = useApiFormConfig()
  const apiListConfigModels = useApiListConfig()

  const handleGet = () => {
    apiFormConfig.get(`${nodeID}/node`)
    apiListConfigModels.get('models')
  }

  const hasSettings = useMemo(() => !!apiFormConfig.form.id, [apiFormConfig])

  const handleDeleteSettings = async () => {
    await apiFormConfig.delete(apiFormConfig.form.id)
    await apiFormConfig.get(`${nodeID}/node`)
  }
  const handleSubmit = data => {
    if (data.id) apiFormConfig.put(data.id, data)
    else apiFormConfig.post(data)
  }

  const HeaderMenu = () =>
    <Menu>
      <MenuDelete text="delete custom settings" onClick={handleDeleteSettings} show={hasSettings} />
    </Menu>

  return (
    <React.Fragment>
      <BreadcrumbView Icon={IconAiDocument} text="settings" onMatch={handleGet}>
        <CardFullHeightScrollY title="AI Settings" HeaderMenu={HeaderMenu}>
          <Form state={apiFormConfig.form} onSubmit={handleSubmit} onRefresh={handleGet}>
            <FormText label="system prompt" name="systemPrompt" autoFocus />
            <FormRange label="temperature" name="temperature" min="0" max="1" step="0.1" />
            <FormCheckbox label="reference documents" name="referenceDocuments" />
            <FormSelectListStr label="chat model" name="chatModel" state={apiListConfigModels.items} />
            <FormSubmitBack text="update config" />
          </Form>
        </CardFullHeightScrollY>
      </BreadcrumbView>
    </React.Fragment>
  )
}
