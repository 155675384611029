import { useEffect, useMemo, useState } from 'react'
import { useFileUploadContent } from '../../legacy/storage/hooks'
import { Progress } from '../../legacy/progress'

export const UploadListItem = ({ item, onComplete }) => {
  const [progress, updateProgress] = useState(0)
  const [complete, updateComplete] = useState(false)

  const asset = useMemo(() => item.asset || null, [item])
  const file = useMemo(() => item.file || null, [item])

  const key = useMemo(() => asset.key, [asset])
  const name = useMemo(() => file.name || '', [file])
  const length = useMemo(() => file.size || 0, [file])

  const handleUpdateProgress = progress => updateProgress(progress)

  const handleComplete = () => {
    updateComplete(true)
    onComplete && onComplete(item)
  }

  const [upload] = useFileUploadContent(null, handleUpdateProgress, handleComplete, null)

  useEffect(() => {
    if (!complete && file) {
      upload(key, file)
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [])

  return (
    <div>
      <div>
        {name}
        <Progress current={progress} length={length} />
      </div>
    </div>
  )
}
