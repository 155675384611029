import React from 'react'
import { BreadcrumbView } from '../../../../../context/breadcrumb.context'
import { FileImageSwap } from '../../../../../shared/legacy/file/file-image-swap'
import { useOrganizationForm, useWhiteLabelForm } from '../../../../../api/state'
import { useParamsInt } from '../../../../../shared/hooks/location'
import { Form, FormText, FormSelectListNum, FormCheckbox, FormSubmitBack } from '../../../../../shared/form/form'
import { Card } from '../../../../../shared/card/card'
import { IconEdit } from '../../../../../shared/icon/icon'
import { ScrollY } from '../../../../../shared/component/scroll'
import { useRoleSysOrganization } from '../../../../../role/hook/role-sys'
import { ThumnailAsset } from '../../../org/node/common/thumbnail/thumbnail-asset'
import { Logo } from '../../../org/node/common/thumbnail/logo'

const limitUser = [1, 5, 10]
const limitCase = [3, 10, 20]

export function OrganizationEdit() {
  const { organizationID } = useParamsInt()

  const roleSysOrganization = useRoleSysOrganization()

  const [organizationFormAction, organizationFormState] = useOrganizationForm()
  const [whiteLableFormActions, whiteLabelFormState] = useWhiteLabelForm()

  const handlePath = async () => {
    await organizationFormAction.get(organizationID)
    await whiteLableFormActions.get(organizationID)
  }

  const handleSubmit = async data => await organizationFormAction.put(data)

  // const handleLogoSave = async file => {
  //   await whiteLableFormActions.putArgs({ path: `${organizationID}/logo`, data: file })
  //   await whiteLableFormActions.get(organizationID)
  // }

  // const handleLogoClear = async () => {
  //   await whiteLableFormActions.delete(`${organizationID}/logo`)
  //   await whiteLableFormActions.get(organizationID)
  // }

  return (
    <React.Fragment>
      <BreadcrumbView Icon={IconEdit} text={organizationFormState.form.name} onPath={handlePath}>
        <ScrollY>
          <Card title="Organization Edit">
            <Form
              state={organizationFormState.form}
              submitting={organizationFormState.submitting}
              onSubmit={handleSubmit}
              disabled={!roleSysOrganization.accessUpdate}>
              <FormText label="Name" name="name" placeholder="organization name" required />
              <FormSelectListNum label="user limit" name="limitUser" nullText="Unlimited" state={limitUser} />
              <FormSelectListNum label="case limit" name="limitCase" nullText="Unlimited" state={limitCase} />
              <FormCheckbox label="feature analytics" name="featureAnalytic" />
              <FormCheckbox label="feature multiplayer" name="featureMultiplayer" />
              <FormCheckbox label="feature assessments" name="featureAssessment" />
              <FormCheckbox label="feature heatmap" name="featureHeatmap" />
              <FormCheckbox label="feature AR" name="featureAR" />
              <FormCheckbox label="access view public content" name="accessViewPublic" />
              <FormSubmitBack text="update organization" />
            </Form>
          </Card>

          <Logo organizationID={organizationID} />

          {/* <ThumnailAsset nodeID={nodeID} /> */}
          {/* <FileImageSwap
            title="branding image"
            rowColWidth="col-12 col-sm-8 col-md-6 col-xl-4"
            // ratio="13.7:3"
            logoURL={whiteLabelFormState.form.logoURL}
            onSave={handleLogoSave}
            onClear={handleLogoClear}
          /> */}
        </ScrollY>
      </BreadcrumbView>
    </React.Fragment>
  )
}
