import React, { useEffect } from 'react'
import { useLocation } from 'react-router-dom'
import { useLoginRedirect } from '../layout/unauthorized/auth/page/hooks'

export const RedirectDashboard = ({ children }) => {
  const { pathname } = useLocation()

  const loginRedirect = useLoginRedirect()

  useEffect(() => {
    const elements = pathname.split('/')

    if (elements[1] === '') {
      loginRedirect()
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [])

  return (
    <React.Fragment>
      {children}
    </React.Fragment>
  )
}
