import React from 'react'
import { InputMessage } from './input'
import { Query } from './query'
import { Item } from './item'

export const ScreenConversation = ({ items, query, submitting, onSubmit }) => {
  return (
    <React.Fragment>
      <div className="flex-fill" style={{ minHeight: 0 }}>
        <div className="border rounded p-2 w-100 h-100 overflow-x-scroll">
          <div className="px-3">
            {items && items.map((item, i) => <Item key={i} item={item} />)}
            <Query value={query} />
          </div>
        </div>
      </div>
      <div className="mt-2">
        <InputMessage submitting={submitting} onSubmit={onSubmit} />
      </div>
    </React.Fragment>
  )
}
