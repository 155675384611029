import { useMemo } from 'react'
import moment from 'moment'

export const ItemCreated = ({ value }) => {
  const date = useMemo(() => moment(value).format('llll'), [value])

  return (
    <div className="small">
      {date}
    </div>
  )
}
