import React from 'react'
import { ItemContent } from './item-content'

export const Query = ({ value }) => {
  return (
    <React.Fragment>
      {value &&
        <div className="m-2">
          <ItemContent content={value} />
          <div className="progress m-3">
            <div className="progress-bar progress-bar-striped progress-bar-animated w-100" />
          </div>
        </div>}
    </React.Fragment>
  )
}
