import React from 'react'
import { Route } from 'react-router-dom'
import { ProviderAdd } from './page/provider-add'
// import { ProviderAuthView } from './page/provider-auth-view'
// import { ProviderAuthList } from './page/provider-auth-list'
import { ProviderEdit } from './page/provider-edit'
import { ProviderList } from './page/provider-list'
import { ProviderOrganizationList } from './page/provider-organization-list'
import { ProviderRecycle } from './page/provider-recycle'
import { Delete } from '../../../../shared/page/page-delete'
import { Purge } from '../../../../shared/page/page-purge'
import { useApiFormProvider } from '../../../../api-new/state/auth'
import { LogEventSys } from '../../../../shared/page/log-event/log-event-sys'
import { ProviderOrganizationAdd } from './page/provider-organization-add'

export const LayoutProvider = () => {
  return (
    <React.Fragment>
      <Route path="/admin/provider/list" component={ProviderList} />
      <Route path="/admin/provider/list/edit/:providerID" component={ProviderEdit} />

      <Route path="/admin/provider/list/edit/:deleteID/delete">
        <Delete useApiForm={useApiFormProvider} titleParam="providerName" successPath="/admin/provider/list" />
      </Route>

      <Route path="/admin/provider/list/edit/:providerID/organization" component={ProviderOrganizationList} />
      <Route path="/admin/provider/list/edit/:providerID/organization/add" component={ProviderOrganizationAdd} />

      <Route path="/admin/provider/list/add" component={ProviderAdd} />
      <Route path="/admin/provider/list/recycle" component={ProviderRecycle} />

      <Route path="/admin/provider/list/recycle/purge/:purgeID">
        <Purge useApiForm={useApiFormProvider} titleParam="providerName" successPath="/admin/provider/list/recycle" />
      </Route>

      {/* <Route path="/admin/provider/list/edit/:providerID/auth/:providerID" component={ProviderAuthList} /> */}
      {/* <Route path="/admin/provider/list/edit/:providerID/auth/:providerID/view/:authProviderID" component={ProviderAuthView} /> */}

      <Route path="/admin/provider/list/activity-all">
        <LogEventSys subject="provider" />
      </Route>
      <Route path="/admin/provider/list/activity-id/:id">
        <LogEventSys subject="provider" />
      </Route>
    </React.Fragment>
  )
}
