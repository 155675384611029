import React, { useMemo } from 'react'
import { IconDown } from '../icon/icon'

export const AlertError = ({ error }) => {
  const lines = useMemo(() => (error ? error.split('\n') : []), [error])

  return (
    <React.Fragment>
      {lines.length > 0
        ? <div className="alert alert-danger">
            <div className="d-flex">
              <div className="flex-fill">A error occured</div>
              <a href="#errorCollapse" data-bs-toggle="collapse" role="button">
                <IconDown />
              </a>
            </div>
            <div id="errorCollapse" className="collapse">
              <div className="m-2">
                {lines.map((line, i) =>
                  <div key={i}>
                    {line}
                  </div>
                )}
              </div>
            </div>
          </div>
        : <React.Fragment />}
    </React.Fragment>
  )
}
