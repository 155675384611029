import React, { useCallback, useMemo, useState } from 'react'

export const FileDrop = ({ children, allowedFileTypes, onDrop }) => {
  const [isOver, updateIsOver] = useState(false)
  const [errors, updateErrors] = useState([])

  const types = useMemo(() => allowedFileTypes || [], [allowedFileTypes])

  const checkTypes = useCallback(items => {
    let values = Object.values(items)

    let errors = []

    values.forEach(item => {
      if (item.type === '') {
        errors.push('folders not allowed')
      } else {
        let found = types.includes(item.type)
        if (!found) {
          errors.push(`${item.type} not allowed`)
        }
      }
    })

    return errors
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [])

  const handleDragEnter = e => {
    e.preventDefault()
    let errors = checkTypes(e.dataTransfer.items)
    updateErrors(errors)
    updateIsOver(true)
  }

  const handleDragLeave = e => {
    e.preventDefault()
    updateErrors([])
    updateIsOver(false)
  }

  const handleDrop = e => {
    e.preventDefault()

    updateIsOver(false)

    if (errors.length > 0) {
      return
    }

    if (e.dataTransfer && e.dataTransfer.files.length) {
      onDrop && onDrop(e.dataTransfer.files)
    }
  }

  const handleDragOver = e => e.preventDefault()

  return (
    <div className="h-100" onDragEnter={handleDragEnter} onDragLeave={handleDragLeave} onDrop={handleDrop} onDragOver={handleDragOver}>
      {isOver
        ? <DropZone errors={errors} />
        : <div className="h-100">
            {children}
          </div>}
    </div>
  )
}

const DropZone = ({ errors }) => {
  const hasErrors = useMemo(() => !!errors.length, [errors])

  const handleSupress = e => {
    e.preventDefault()
    e.stopPropagation()
  }

  return (
    <div className="h-100 p-2" onDragEnter={handleSupress} onDragLeave={handleSupress}>
      <div className={`h-100 border rounded ${hasErrors ? 'border-danger' : 'border-success'}`}>
        <div className="d-flex flex-column align-items-center justify-content-center h-100">
          <div className="border rounded p-2">
            {hasErrors
              ? <div>
                  {errors.map((error, i) =>
                    <div key={i}>
                      {error}
                    </div>
                  )}
                </div>
              : <div>File Drop</div>}
          </div>
        </div>
      </div>
    </div>
  )
}
