import { IconAiDocument } from '../../../../../../../../shared/icon/icon'

export const ItemChoice = ({ choice }) => {
  return (
    <div className="d-flex">
      <div className="p-1">
        <IconAiDocument />
      </div>
      <div className="form-control border-0">
        {choice.message.content}
      </div>
    </div>
  )
}
