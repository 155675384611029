import React, { useMemo, useState } from 'react'
import { useApiFormCompletion, useApiFormConfig } from '../../../../../../../api-new/state/ai'
import { BreadcrumbView } from '../../../../../../../context/breadcrumb.context'
import { IconAiDocument } from '../../../../../../../shared/icon/icon'
import { CardFullHeight } from '../../../../../../../shared/card/card-full-height'
import { useLoad } from '../../../../../../../shared/hooks/load'
import { Menu, MenuDelete } from '../../../../../../../shared/menu/menu'
import { ScreenOpening } from './components/screen-opening'
import { ScreenConversation } from './components/screen-converse'
import { ModalDialog, useModalActionID } from '../../../../../../../shared/component/modal-dialog'
import { Settings } from './components/settings'

export const AiChat = ({ nodeID }) => {
  const apiFormConfig = useApiFormConfig()
  const apiFormCompletion = useApiFormCompletion('/complete-text')

  const [settingTargetID, showSettingDialog] = useModalActionID()

  const [settings, updateSettings] = useState({})
  const [items, updateItems] = useState([])
  const [query, updateQuery] = useState('')

  const showOpening = useMemo(() => items.length === 0 && !query, [items, query])

  useLoad(() => updateSettings(apiFormConfig.form), [apiFormConfig])
  useLoad(() => apiFormConfig.get(`${nodeID}/node`), [])

  const handleSubmit = async content => {
    updateQuery(content)

    let messages = items.map(item => {
      return {
        role: 'user',
        content: item.content
      }
    })

    messages.push({ role: 'user', content: content })

    let request = {
      courseID: nodeID,
      stream: false,
      messages: messages,
      temperature: settings.temperature || 0.5,
      referenceDocuments: settings.referenceDocuments || false,
      chatModel: settings.chatModel || ''
    }

    let result = await apiFormCompletion.post(request)

    let item = {
      content,
      result
    }

    updateItems([...items, item])

    updateQuery('')
  }

  const handleClear = () => updateItems([])

  const handleSettings = () => showSettingDialog()

  const HeaderMenu = () =>
    <Menu>
      <MenuDelete text="clear chat" onClick={handleClear} show />
      <MenuDelete text="settings" onClick={handleSettings} show />
    </Menu>

  return (
    <React.Fragment>
      <BreadcrumbView Icon={IconAiDocument} text="ai chat">
        <CardFullHeight title="AI Chat" HeaderMenu={HeaderMenu}>
          <ModalDialog target={settingTargetID} header="Settings">
            <Settings value={settings} onChange={updateSettings} />
          </ModalDialog>

          <div className="d-flex flex-column h-100">
            {showOpening
              ? <ScreenOpening submitting={apiFormCompletion.submitting} onSubmit={handleSubmit} />
              : <ScreenConversation items={items} query={query} submitting={apiFormCompletion.submitting} onSubmit={handleSubmit} />}
          </div>
        </CardFullHeight>
      </BreadcrumbView>
    </React.Fragment>
  )
}
