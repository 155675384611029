import React, { useMemo, useState } from 'react'
import { ImageThumbnail } from '../image-thumbnail/image-thumbnail'
import { FileSelect } from '../../../../../../../shared/legacy/file/file-select'
import { FileToImageURL } from '../image-thumbnail/hook'
import { ButtonPrimary, ButtonSecondary } from '../../../../../../../shared/component/button'
import { ImageUrlToBlob } from './components/hook'
import { ImageCropper } from './components/image-cropper'
import { useLoad } from '../../../../../../../shared/hooks/load'
import { Card } from '../../../../../../../shared/card/card'

export const ImageEditor = ({ blob, width, height, onSave, onDelete }) => {
  const [liveImageURL, updateLiveImageURL] = useState(null)
  const [previewImageURL, updatePreviewImageURL] = useState(null)
  const [editImageURL, updateEditImageURL] = useState(null)

  const showEdit = useMemo(() => !!editImageURL, [editImageURL])

  const hasLive = useMemo(() => !!liveImageURL, [liveImageURL])
  const hasPreview = useMemo(() => !!previewImageURL, [previewImageURL])

  useLoad(
    async () => {
      if (blob && blob.size) {
        let imageURL = await FileToImageURL(blob)
        updateLiveImageURL(imageURL)
      } else {
        updateLiveImageURL(null)
      }

      updatePreviewImageURL(null)
    },
    [blob]
  )

  const handleSelect = async file => {
    let imageURL = await FileToImageURL(file)
    updatePreviewImageURL(imageURL)
  }

  const handleSave = async () => {
    let blob = await ImageUrlToBlob(previewImageURL)
    onSave && onSave(blob)
  }

  const handleCancel = () => {
    updatePreviewImageURL(null)
  }

  const handleEdit = () => {
    updateEditImageURL(previewImageURL)
  }

  const handleDelete = () => onDelete && onDelete()

  const handleContinueCropper = imageURL => {
    updatePreviewImageURL(imageURL)
    updateEditImageURL(null)
  }

  const handleCancelCropper = () => {
    updateEditImageURL(null)
  }

  return (
    <React.Fragment>
      {showEdit
        ? <React.Fragment>
            <div>EDIT</div>
            <ImageCropper
              imageURL={editImageURL}
              width={width}
              height={height}
              aspect={13.7 / 3}
              onContinue={handleContinueCropper}
              onCancel={handleCancelCropper}
            />
          </React.Fragment>
        : <React.Fragment>
            <div>PREVIEW</div>

            <Card>
              <ImageThumbnail imageURL={previewImageURL || liveImageURL} width={width} height={height} />
            </Card>

            <FileSelect onSelect={handleSelect} accept=".jpeg,.png" maxSize={1024 * 512} />

            <div>
              {!hasPreview && hasLive && <ButtonSecondary onClick={handleDelete}>delete</ButtonSecondary>}
              {hasPreview && <ButtonSecondary onClick={handleCancel}>cancel</ButtonSecondary>}
              {hasPreview && <ButtonPrimary onClick={handleEdit}>edit</ButtonPrimary>}
              {hasPreview && <ButtonPrimary onClick={handleSave}>save</ButtonPrimary>}
            </div>
          </React.Fragment>}
    </React.Fragment>
  )
}
