import React from 'react'
import { useLoad } from '../../../../../../shared/hooks/load'
import { ImageEditor } from './image-editor/image-editor'
import { useApiBlobFilePublic, useApiFormFilePublic } from '../../../../../../api-new/state/storage'

export const Logo = ({ organizationID }) => {
  const apiFormFilePublic = useApiFormFilePublic()
  const apiBlobFilePublic = useApiBlobFilePublic()

  useLoad(async () => {
    await apiBlobFilePublic.get(`${organizationID}/stream-safe`)
  }, organizationID)

  const handleSave = async blob => {
    await apiFormFilePublic.postArg(`/${organizationID}`, blob)
    await apiBlobFilePublic.get(`${organizationID}/stream-safe`)
  }

  const handleDelete = async () => {
    await apiFormFilePublic.delete(organizationID)
    apiBlobFilePublic.init()
  }

  return (
    <React.Fragment>
      <ImageEditor blob={apiBlobFilePublic.blob} height={84} onSave={handleSave} onDelete={handleDelete} />
    </React.Fragment>
  )
}
