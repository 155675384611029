import React from 'react'
import { Card } from '../../../../../../../../shared/card/card'
import { InputMessage } from './input'

export const ScreenOpening = ({ submitting, onSubmit }) => {
  return (
    <React.Fragment>
      <div className="flex-fill align-self-center w-50">
        <div className="d-flex h-100">
          <div className="align-self-center w-100">
            <Card>
              <div>What can I help you with?</div>
              <div>
                <InputMessage submitting={submitting} onSubmit={onSubmit} />
              </div>
            </Card>
          </div>
        </div>
      </div>
    </React.Fragment>
  )
}
