import React, { forwardRef, useImperativeHandle, useState } from 'react'

export const Collapse = forwardRef(({ children, text, onShow, onHide }, ref) => {
  const [show, updateShow] = useState(false)

  useImperativeHandle(
    ref,
    () => {
      return {
        show: () => updateShow(true),
        hide: () => updateShow(false)
      }
    },
    []
  )

  const handleClick = () => {
    if (show) {
      updateShow(false)
      onHide && onHide()
    } else {
      updateShow(true)
      onShow && onShow()
    }
  }

  return (
    <React.Fragment>
      <span className="text-decoration-underline" style={{ cursor: 'pointer' }} onClick={handleClick}>
        {text}
      </span>
      <div className={`collapse ${show && 'show'}`}>
        {children}
      </div>
    </React.Fragment>
  )
})
