export const useSelectFile = selectedFiles => {
  return () => {
    let input = document.createElement('input')
    input.setAttribute('type', 'file')
    input.setAttribute('multiple', 'true')
    document.body.appendChild(input)

    let listener = e => {
      selectedFiles && selectedFiles(e.target.files)
      input.removeEventListener('change', listener)
      document.body.removeChild(input)
    }

    input.addEventListener('change', listener)
    input.click()
  }
}
