import React, { useMemo, useState } from 'react'
import { useHistory } from 'react-router-dom'
import { useAuthContext } from '../../../../context/auth.context'
import { NavLink, PositionCenter, TitleMain, TitleSub } from './component'
import { useBuildRoutePath } from '../../../../page/hook'
import { Form, FormText, FormPassword, FormSubmit } from '../../../../shared/form/form'
import { useQueryParam } from '../../../../shared/hooks/location'
import { useDocAuthRedirect } from '../../../../shared/hooks/doc'
import { useLoginRedirect } from './hooks'
import { LoginDeviceModal } from './login-device-modal'
import { ModalLinkShow } from '../../../../shared/component/modal-dialog'
import { IconLoginDevice } from '../../../../shared/icon/icon'
// import { useNotificationContext } from '../../../../context/notification/context'
import { ButtonPrimary } from '../../../../shared/component/button'
import { AlertError } from '../../../../shared/alert/alert-error'

export const LoginEmail = () => {
  const history = useHistory()

  const authContext = useAuthContext()
  // const notificationContext = useNotificationContext()

  const loginRedirect = useLoginRedirect()

  const [showPassword, updateShowPassword] = useState(false)
  const [email, updateEmail] = useState('')

  const validEmail = useMemo(() => !!email, [email])

  let redirect = useQueryParam('redirect')
  let redirectUrl = useQueryParam('redirect-url')

  const buildRoutePath = useBuildRoutePath()

  const docAuthRedirect = useDocAuthRedirect()

  const handleNext = async () => {
    if (!email) {
      return
    }

    let emailParts = email.split('@')

    let emailURL = emailParts.length === 2 ? emailParts[1] : ''

    if (emailURL) {
      const providers = await authContext.actions.getProvidersUrl(emailURL)

      if (providers.length) {
        let path = buildRoutePath('/auth/providers', { email, redirect, redirectUrl })
        history.push(path)
        return
      }
    }

    var emailExists = await authContext.actions.checkEmail(email)

    if (!emailExists) {
      history.push('/public/register')
      return
    }

    updateShowPassword(true)
  }

  const handleSubmit = async data => {
    if (!data.password) {
      handleNext()
      return
    }

    await authContext.actions.loginEmail(data)

    if (redirectUrl) {
      docAuthRedirect(redirectUrl)
      return
    }

    loginRedirect(redirect)
  }

  const errorMessage = useMemo(
    () => {
      return authContext.state.authEmailFormState.error
    },
    [authContext.state.authEmailFormState]
  )

  return (
    <React.Fragment>
      <PositionCenter>
        <AlertError error={errorMessage} />
        <TitleMain value="Login" />
        <TitleSub value="Sign in to your account" />

        <Form onSubmit={handleSubmit}>
          <FormText
            id="email"
            name="email"
            placeholder="email/login"
            required
            shouldDirty
            autoFocus={true}
            onChange={updateEmail}
            onEnter={handleNext}
          />

          {showPassword &&
            <div>
              <FormPassword name="password" required autoFocus shouldDirty />
              <div className="text-end">
                <NavLink text="Forgot Password?" to="/auth/password-recover/request" />
              </div>
            </div>}

          {showPassword &&
            <div className="my-3">
              <FormSubmit text="sign in" />
            </div>}
        </Form>

        {!showPassword &&
          <div className="my-3">
            <ButtonPrimary text="Next" disabled={!validEmail} onClick={handleNext} />
          </div>}

        <div>
          <div className="row justify-content-center g-2">
            <div className="col-7 text-center m-1">
              <span>-Or-</span>
            </div>
          </div>
          <div className="row justify-content-center g-2">
            <div className="col-8 col-xl-8 col-xxl-8">
              <ModalLinkShow
                className="btn btn-light border w-100 text-start"
                target="device"
                Icon={IconLoginDevice}
                text="Login from another device"
              />
            </div>
            {/* <div className="col-9 col-xl-5 col-xxl-6">
              <NavLink className="btn btn-light border w-100 text-start" Icon={IconLoginPin} text="I have a pin" to="/auth/pin" />
            </div> */}
          </div>
        </div>
      </PositionCenter>

      <LoginDeviceModal target="device" />
    </React.Fragment>
  )
}

// const Alert = ({ message }) => {
//   return (
//     <div className="alert alert-danger" role="alert">
//       {message}
//     </div>
//   )
// }
