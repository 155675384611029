import React, { useRef } from 'react'
import { BreadcrumbView } from '../../../../../context/breadcrumb.context'
import {
  Menu,
  MenuActivityLog,
  MenuAdd,
  MenuDelete,
  MenuEdit,
  MenuExportCsv,
  MenuExportExcel,
  MenuItem,
  MenuRecycle,
  MenuTableSettingToggle
} from '../../../../../shared/menu/menu'
import { CardFullHeightScrollY } from '../../../../../shared/card/card-full-height-scroll-y'
import { TableBuilder, Column, ColumnMenu, ColumnYesNo } from '../../../../../shared/table-builder/table-builder'
import { useApiListProvider } from '../../../../../api-new/state/auth'
import { IconProvider, IconOrganization } from '../../../../../shared/icon/icon'
import { useRoleSysProvider } from '../../../../../role/hook/role-sys'

export const ProviderList = () => {
  const apiListProvider = useApiListProvider()

  const roleSysProvider = useRoleSysProvider()

  const handleReady = async () => await apiListProvider.get()

  const tableRef = useRef()
  const handleExportCsv = () => tableRef.current.exportCsv()
  const handleExportExcel = () => tableRef.current.exportExcel()
  const handleTableSettingToggle = () => tableRef.current.toggleSideBar()

  const HeaderMenu = () =>
    <Menu>
      <MenuAdd text="add" to="/admin/provider/list/add" show={roleSysProvider.accessCreate} />
      <MenuRecycle to="/admin/provider/list/recycle" show={roleSysProvider.accessDelete} />

      <MenuExportCsv onClick={handleExportCsv} show />
      <MenuExportExcel onClick={handleExportExcel} show />
      <MenuTableSettingToggle onClick={handleTableSettingToggle} show />
      <MenuActivityLog to="/admin/provider/list/activity-all" show />
    </Menu>

  return (
    <React.Fragment>
      <BreadcrumbView Icon={IconProvider} text="PROVIDERS">
        <CardFullHeightScrollY title="providers" HeaderMenu={HeaderMenu}>
          <TableBuilder ref={tableRef} apiList={apiListProvider} onReady={handleReady}>
            <Column field="providerName" header="provider name" sortable filter />
            <Column field="displayName" header="display name" sortable filter />
            <Column field="url" sortable filter />
            <ColumnYesNo field="active" sortable filter />
            <ColumnMenu
              menu={row =>
                <Menu small>
                  {/* <MenuItem
                    Icon={IconProvider}
                    text="auth"
                    to={`/admin/provider/list/edit/${row.id}/auth/${row.id}`}
                    show={roleSysProvider.accessUpdate}
                  /> */}
                  <MenuEdit to={`/admin/provider/list/edit/${row.id}`} show={roleSysProvider.accessUpdate} />
                  <MenuItem
                    Icon={IconOrganization}
                    text="organizations"
                    to={`/admin/provider/list/edit/${row.id}/organization`}
                    show={roleSysProvider.accessRead}
                  />
                  <MenuDelete to={`/admin/provider/list/edit/${row.id}/delete`} show={roleSysProvider.accessDelete} />
                  <MenuActivityLog to={`/admin/provider/list/activity-id/${row.id}`} show />
                </Menu>}
            />
          </TableBuilder>
        </CardFullHeightScrollY>
      </BreadcrumbView>
    </React.Fragment>
  )
}
