import React, { useMemo, useState } from 'react'
import { Collapse } from './collapse'

export const ItemCitations = ({ citations }) => {
  const hasCitations = useMemo(() => citations.length > 0, [citations])

  const documents = useMemo(
    () => {
      if (!hasCitations) {
        return []
      }

      let hold = citations.map(citation => {
        return citation.documentName
      })

      return hold
    },
    [hasCitations, citations]
  )

  const [showIndex, updateShowIndex] = useState(null)

  const handleShowIndex = index => updateShowIndex(index)

  const showDocument = useMemo(
    () => {
      if (showIndex === null) {
        return null
      }

      return citations[showIndex]
    },
    // eslint-disable-next-line react-hooks/exhaustive-deps
    [showIndex]
  )

  const handleShow = () => {}

  const handleHide = () => {
    updateShowIndex(null)
  }

  return (
    <React.Fragment>
      {hasCitations &&
        <Collapse text="show citations" onShow={handleShow} onHide={handleHide}>
          <div className="d-flex small">
            {documents.map((document, i) =>
              <div key={i} className="m-1 text-decoration-underline" style={{ cursor: 'pointer' }} onClick={() => handleShowIndex(i)}>
                {document}
              </div>
            )}
          </div>
          {showDocument &&
            <div>
              {showDocument.documentChunkContents}
            </div>}
        </Collapse>}
    </React.Fragment>
  )
}
