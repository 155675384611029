import { Link } from 'react-router-dom'
import { useDataContext } from '../../data-context'
import React, { useMemo, useState } from 'react'
import { useLoad } from '../../../../../../shared/hooks/load'
import { IconVideo } from '../../../../../../shared/icon/icon'
import { useApiFormFileContent } from '../../../../../../api-new/state/storage'

export const GridItem = ({ nodeID, Icon, FooterMenu, title, to, video }) => {
  return (
    <div className="col-12 col-sm-6 col-md-4 col-xl-3">
      <div className="card" style={{ height1: 300 }}>
        <div className="card-body p-1">
          <div className="d-flex flex-column h-100">
            <div className="flex-fill">
              <Link to={to}>
                <div>
                  {video &&
                    <div
                      className="text-primary"
                      style={{ position: 'absolute', top: '50%', left: '50%', transform: 'translate(-50%, -135%)', opacity: '60%' }}>
                      <IconVideo className="" size="" transform="grow-50" />
                    </div>}
                  <div>
                    <ThumbnailImage nodeID={nodeID} />
                  </div>
                </div>
              </Link>
            </div>
            <div className="d-flex border-top">
              <div className="mt-2">
                {Icon && <Icon />}
              </div>
              <div className="flex-fill ms-2 mt-2 text-truncate">
                {title}
              </div>
              <div>
                {FooterMenu && <FooterMenu />}
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  )
}

const ThumbnailImage = ({ nodeID }) => {
  const dataContext = useDataContext()

  const apiBlobFileContent = useApiFormFileContent()

  // eslint-disable-next-line react-hooks/exhaustive-deps
  const thumbnail = useMemo(() => dataContext.thumbnailArray.find(asset => asset.nodeID === nodeID), [nodeID])

  const loadThumbnailImage = async key => {
    let file = await apiBlobFileContent.getBlob(`${key}/stream-safe`)

    if (file.size) {
      return await getBase64(file)
    }

    return null
  }

  const [image, updateImage] = useState()

  useLoad(
    async () => {
      if (thumbnail) {
        let image = dataContext.thumbnailCache.get(thumbnail.key)

        if (image) {
          updateImage(image)
          return
        }

        image = await loadThumbnailImage(thumbnail.key)

        dataContext.thumbnailCache.add(thumbnail.key, image)
        updateImage(image)
      }
    },
    [thumbnail]
  )

  return (
    <React.Fragment>
      {image ? <img className="course-image" src={image} alt="img" /> : <img className="course-image" src="/content_placeholder.png" alt="img" />}
    </React.Fragment>
  )
}

const getBase64 = blob => {
  return new Promise((resolve, reject) => {
    let reader = new FileReader()
    reader.onload = () => {
      resolve(reader.result)
    }
    reader.readAsDataURL(blob)
  })
}
