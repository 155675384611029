import { useMemo } from 'react'
import { useFormContext } from 'react-hook-form'
import { useFormDisable } from './form'
import { FormatLabel } from './format-label'
import { useFormRequired } from './hooks/input-hooks'

export const FormSelectListStr = ({ label, subtext, name, state, required, readOnly, valueAsNumber }) => {
  const { register, watch } = useFormContext()

  let formDisabled = useFormDisable()

  let watchValue = watch(name)

  useFormRequired(name, required)

  const items = useMemo(() => state || [], [state])

  return (
    <FormatLabel name={name} label={label} subtext={subtext} hasValue={!!watchValue} required={required}>
      <select className="form-control" {...register(name, { required, valueAsNumber })} value={watchValue || ''} readOnly={readOnly} disabled={formDisabled}>
        <option value="">Select</option>
        {items.sort((a, b) => a.localeCompare(b)).map((item, i) =>
          <option key={i} value={item}>
            {item}
          </option>
        )}
      </select>
    </FormatLabel>
  )
}
