import React from 'react'
import { useFormContext } from 'react-hook-form'
import { useFormDisable } from './form'
import { FormatLabel } from './format-label'
import { useFormOnChange, useFormRequired, useFormShouldDirty } from './hooks/input-hooks'

export const FormRange = ({ className, label, subtext, name, required, readOnly, defaultValue, shouldDirty, hidden, small, min, max, step, onChange }) => {
  const { register, watch } = useFormContext()

  let watchValue = watch(name)
  let formDisabled = useFormDisable()

  useFormRequired(name, required)
  useFormShouldDirty(name, defaultValue, shouldDirty)
  useFormOnChange(name, onChange)

  return (
    <FormatLabel className={className} name={name} label={label} subtext={subtext} hasValue={!!watchValue} required={required} hidden={hidden}>
      <div className="input-group">
        <span className="input-group-text">
          {watchValue}
        </span>
        <input
          className={`form-control ${small && 'form-control-sm'}`}
          type="range"
          disabled={formDisabled}
          readOnly={readOnly}
          min={min}
          max={max}
          step={step}
          value={watchValue}
          {...register(name, { required, valueAsNumber: true })}
        />
      </div>
    </FormatLabel>
  )
}
