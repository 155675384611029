import React, { useContext, useEffect, useMemo } from 'react'
import { ButtonPrimary } from '../../../../../shared/legacy/button'
import { AuthContext } from '../../../../../context/auth.context'
import { Card } from '../../../../../shared/card/card'
import { useApiListLoginProvider } from '../../../../../api-new/state/auth'

export const LoginProvider = () => {
  const authContext = useContext(AuthContext)

  const apiListLoginProvider = useApiListLoginProvider()

  const loginID = useMemo(() => authContext.state.jwtClaims.loginID, [authContext.state.jwtClaims])
  const email = useMemo(() => authContext.state.jwtClaims.email, [authContext.state.jwtClaims])

  useEffect(
    () => {
      if (loginID) {
        apiListLoginProvider.get(loginID)
      }
    },
    // eslint-disable-next-line react-hooks/exhaustive-deps
    [loginID]
  )

  const handleDisconect = async id => {
    try {
      await apiListLoginProvider.delete(`${id}`)
      await apiListLoginProvider.get(`${loginID}`)
    } catch (error) {
      debugger
    }
  }

  const title = useMemo(() => `Provider Disconnect ${email}`, [email])

  return (
    <React.Fragment>
      <Card title={title} disableCase>
        {apiListLoginProvider.items.map((authProvider, i) =>
          <Card key={i}>
            <div className="py-2">
              <div>
                Provider: {authProvider.provider}
              </div>
              <div>
                Authorized On: {authProvider.authorizedOn}
              </div>
              <div>
                ipAddress: {authProvider.ipAddress}
              </div>
              <div>
                ipLocation: {authProvider.ipLocation}
              </div>
              <div>
                device type: {authProvider.deviceType}
              </div>
            </div>
            <div className="py-2">
              <ButtonPrimary text="disconect" onClick={() => handleDisconect(authProvider.id)} />
            </div>
          </Card>
        )}
      </Card>
    </React.Fragment>
  )
}
