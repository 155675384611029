import React from 'react'
import { Route } from 'react-router-dom'
import { NodeContentGrid } from './node-content/node-content-grid'
import { NodeTemplateGrid } from './node-template/node-template-grid'
import { DataProvider } from './data-context'
import { NodeLibraryGrid } from './node-library/node-library-grid'
import { ContextUploadManager } from '../../../../shared/manager-upload/context/context-upload-manager'

export const LayoutContent = () => {
  return (
    <React.Fragment>
      <ContextUploadManager>
        <DataProvider>
          <Route path="/org/node-content" component={NodeContentGrid} />
          <Route path="/org/node-template" component={NodeTemplateGrid} />
          <Route path="/org/node-library" component={NodeLibraryGrid} />
        </DataProvider>
      </ContextUploadManager>
    </React.Fragment>
  )
}
