import React, { useEffect, useMemo, useState } from 'react'
import { useLoad } from '../shared/hooks/load'
import { useQueryParam } from '../shared/hooks/location'
import { Card } from '../shared/card/card'
import { useApiFormAuthProvider } from '../api-new/state/auth'

export const OpenComplete = () => {
  const token = useQueryParam('token')

  // const [details, updateDetails] = useState({})
  const [error, updateError] = useState()

  const apiFormAuthProvider = useApiFormAuthProvider()

  useLoad(async () => await apiFormAuthProvider.get(`${token}/details`), [])

  let url = useMemo(
    () => {
      if (apiFormAuthProvider.form.redirectSigninURL) {
        // debugger
        let url = new URL(apiFormAuthProvider.form.redirectSigninURL)
        url.searchParams.set('token', token)
        return url
      }

      return null
    },
    // eslint-disable-next-line react-hooks/exhaustive-deps
    [apiFormAuthProvider]
  )

  useEffect(
    () => {
      if (url) {
        try {
          // debugger
          // throw 'TEST ERROR'
          window.location = url.href
        } catch (error) {
          // debugger
          updateError(error)
        }
      }
    },
    [url]
  )

  return (
    <React.Fragment>
      <Card>
        <div className="p-1">OIDC SIGNIN COMPLETE</div>
      </Card>
      {error &&
        <Card>
          <div className="p-1">
            <a href={url}>Click to Open App</a>
          </div>
          <div className="p-1">
            <div>An error occurred opening the EducationXR App from your browser.</div>
            <div>
              {error}
            </div>
          </div>
        </Card>}
    </React.Fragment>
  )
}
