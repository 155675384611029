export const ItemContent = ({ content }) => {
  return (
    <div className="d-flex flex-column">
      <div className="border rounded p-2 w-75 align-self-end bg-body-secondary d-block d-xl-none">
        {content}
      </div>
      <div className="border rounded p-2 w-50 align-self-end bg-body-secondary d-none d-xl-block">
        {content}
      </div>
    </div>
  )
}
