import React, { useMemo } from 'react'
import { useFileDownloadContent } from '../../../../../../shared/legacy/storage/hooks'
import { ButtonPrimary, ButtonSecondary } from '../../../../../../shared/legacy/button'
import { Card } from '../../../../../../shared/card/card'
import { SizeDisplay } from '../../../../../../shared/legacy/size-display'
import { FormatDate } from '../../../../../../shared/legacy/format'
import { FileUpload } from '../../../../../../shared/legacy/storage/file-upload'

export const AssetUnityPackage = ({ asset, onUploadComplete, onDelete }) => {
  // eslint-disable-next-line react-hooks/exhaustive-deps
  const showFile = useMemo(() => asset && asset.length !== 0, [asset, asset.length])

  const fileDownloadContent = useFileDownloadContent()

  const handleDownload = () => fileDownloadContent(asset.name, asset.key)

  const handleDelete = () => onDelete && onDelete(asset.id)

  const handleComplete = file => onUploadComplete && onUploadComplete(asset, file)

  const HeaderComponent = () =>
    <div>
      {showFile &&
        <React.Fragment>
          <ButtonPrimary text="download" small onClick={handleDownload} />
          {onDelete && <ButtonSecondary text="delete" small onClick={handleDelete} />}
        </React.Fragment>}
    </div>

  return (
    <Card title="Unity Package" HeaderComponent={HeaderComponent}>
      {showFile &&
        <div className="p-2">
          <div>
            Filename: {asset.name}
          </div>
          <div>
            Filesize: <SizeDisplay size={asset.length} />
          </div>
          <div>
            Uploaded On: <FormatDate date={asset.updatedAt} />
          </div>
        </div>}
      <div className="pt-3">
        <FileUpload storageKey={asset.key} onComplete={handleComplete} />
      </div>
    </Card>
  )
}
