import { useContextUploadManager } from '../context/context-upload-manager'
import { UploadListItem } from './upload-list-item'

export const UploadList = () => {
  const uploadManager = useContextUploadManager()

  const handleComplete = item => {
    uploadManager.listenerCall(item)
    uploadManager.uploadRemove(item)
  }

  return (
    <div>
      {uploadManager.uploads.map((item, i) => <UploadListItem key={i} item={item} onComplete={handleComplete} />)}
    </div>
  )
}
